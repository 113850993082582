module.exports = {
  linkResolver(doc) {
    if (doc.type === 'article') {
      return `/insight/${doc.uid}`
    }

    if (doc.type === 'testpage') {
      return '/testpage'
    }

    return '/doc/' + doc.id
  },
}
